define("discourse/plugins/discourse-oauth2-basic/discourse/connectors/login-before-modal-body/resend-confirm-login-modal", ["exports", "@ember/component", "@ember/object", "discourse/lib/user-activation", "discourse/lib/waving-hand-url"], function (_exports, _component, _object, _userActivation, _wavingHandUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ResendConfirmLoginModal = _exports.default = (_class = class ResendConfirmLoginModal extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "email", null);
      _defineProperty(this, "username", null);
      _defineProperty(this, "renderOk", false);
    }
    getCookieValue(name) {
      const regex = new RegExp(`(^| )${name}=([^;]+)`);
      const match = document.cookie.match(regex);
      if (match) {
        return match[2];
      }
    }
    get wavingHandURL() {
      return (0, _wavingHandUrl.wavingHandURL)();
    }
    init() {
      super.init(...arguments);
      const e = this.getCookieValue("_oa2e");
      this.set('email', window.atob(e.replace('%0A', '')));
      const u = this.getCookieValue("_oa2u");
      this.set('username', window.atob(u.replace('%0A', '')));
    }
    didRender() {
      super.didRender(...arguments);
      const modalBodyLeft = document.querySelectorAll('.login-modal-body .login-left-side')[1];
      modalBodyLeft.className += ' hidden';
      const modalBodyRight = document.querySelector('.login-modal-body .login-right-side');
      modalBodyRight.className += ' hidden';
      const modalAlert = document.getElementById('modal-alert');
      modalAlert.className += ' hidden';
    }
    sendActivationEmail() {
      const resendButton = document.querySelector('.resend-activation-button');
      resendButton.disabled = true;
      (0, _userActivation.resendActivationEmail)(this.get('username')).then(() => {
        const notActivated = document.getElementById('not-activated');
        notActivated.className += ' hidden';
        const sentActivation = document.getElementById('sent-activation');
        sentActivation.className = '';
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "sendActivationEmail", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sendActivationEmail"), _class.prototype)), _class);
});